import React, { useCallback, ReactNode } from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Title } from '@bojagi/pablo/Typography';
import { useLogger } from '@bojagi/react-event';
import { ArrowButton } from '../components/ArrowButton';
import { ParagraphLarge } from '../components/ParagraphLarge';
import { Container } from '../components/Container';

export interface ClosingBoxProps {
  title: ReactNode;
  children: ReactNode;
}

export function ClosingBox({ title, children }: ClosingBoxProps) {
  const logger = useLogger({
    section: 'closingBox',
  });

  const handleLog = useCallback(context => () => logger.info(context), [logger]);

  return (
    <Container>
      <Box
        bgColor="brand.main"
        textColor="#fff"
        borderRadius={['16px', '36px']}
        maxWidth={1100}
        mb={180}
        mx="auto"
        px={[6, 8, 50]}
        py={[8, 8, 44]}
      >
        <Title>{title}</Title>
        <Box maxWidth={710} mb={55}>
          <ParagraphLarge>{children}</ParagraphLarge>
        </Box>
        <Box display="flex" justifyContent={['center', 'flex-end']}>
          <Box display="flex" flexDirection={['column', 'row']} mx={[0, -6]}>
            <ArrowButton
              to="/register"
              onClick={handleLog({
                component: 'registerBtn',
                action: 'click',
              })}
              mx={[0, 6]}
              mb={[4, 0]}
              variant="primaryInverted"
            >
              Register for Free
            </ArrowButton>
            <ArrowButton
              to="/demo"
              onClick={handleLog({
                component: 'interactiveDemoBtn',
                action: 'click',
              })}
              variant="secondaryInverted"
              mx={[0, 6]}
              mb={[4, 0]}
            >
              Interactive Demo
            </ArrowButton>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}
