import React from 'react';

export interface EmojiProps {
  emoji: string;
  label: string;
}

export function Emoji({ emoji, label }: EmojiProps) {
  return (
    <span role="img" aria-label={label}>
      {emoji}
    </span>
  );
}
