import * as React from 'react';
import { Headline, Paragraph, Title } from '@bojagi/pablo/Typography';
import { Link } from 'gatsby';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { ClosingBox } from '../sections/ClosingBox';
import { Emoji } from '../components/Emoji';
import { SimpleText } from '../sections/SimpleText';

const IndexPage = () => (
  <Layout showLogo>
    <Seo
      title="The Designer Page"
      route="for-designers"
      socialPreviewImage="bojagi-social-preview-designer.jpg"
    />
    <SimpleText>
      <Headline>
        <Emoji emoji="👩‍🎨" label="Designer" /> The Bojagi Designer page
      </Headline>
      <Paragraph>Hello Designer!</Paragraph>
      <Paragraph>
        Great that you stopped by to take a minute to see what Bojagi is all about.{' '}
        <Emoji emoji="☕" label="Coffee" />
      </Paragraph>
      <Paragraph>Bojagi empowers YOU to get fast access to your implemented designs.</Paragraph>
      <Paragraph>
        Why? So that your feedback reaches developers as soon as possible: stop that lingering
        design debt!
      </Paragraph>

      <Title>The problem with design feedback</Title>
      <Paragraph>
        The key to solving UI or UX issues is getting timely feedback to developers. Waiting for
        deployment of staging or QA environments is already too late, as developers have likely
        started their next task.
      </Paragraph>

      <Title>How does Bojagi help?</Title>
      <Paragraph>
        Bojagi gives designers a seat alongside developers during the code approval process,
        empowering you to approve the UI and UX of your product designs.
      </Paragraph>

      <Title>Live components </Title>
      <Paragraph>
        Interact, comment and annotate on live React components and functionality. No screenshots –
        real implementation!
      </Paragraph>

      <Title>Talk to us / Request a demo</Title>
      <Paragraph>
        Schedule a call or to{' '}
        <a href="https://calendly.com/simon-males/designer-user-test?back=1&month=2021-01">
          see Bojagi in action
        </a>
        .{' '}
      </Paragraph>
      <Paragraph>
        See what we have to say to <Link to="/for-ceos">CEOs</Link> and{' '}
        <Link to="/for-developers">Developers</Link> about Bojagi, too!
      </Paragraph>
    </SimpleText>
    <Container>
      <ClosingBox title="Convinced?">
        Try Bojagi today and see how it works in action. We are free during the open Beta!
      </ClosingBox>
    </Container>
  </Layout>
);

export default IndexPage;
