import { ReactNode } from 'react';
import { getSpacing } from '@bojagi/pablo/styleHelpers';
import styled from 'styled-components';
import { Box } from '@bojagi/pablo/Box';

export interface ContainerProps {
  children: ReactNode;
  wide?: boolean;
}

export const Container = styled(Box)<ContainerProps>`
  max-width: ${props => (props.wide ? 1200 : 1100)}px;
  box-sizing: border-box;
  padding: 0 ${getSpacing(6)};
  margin: 0 auto;
`;
