import React, { ReactNode } from 'react';
import { Box } from '@bojagi/pablo/Box';
import { Container } from '../components/Container';

export interface SimpleTextProps {
  children: ReactNode;
}

export function SimpleText({ children }: SimpleTextProps) {
  return (
    <Container>
      <Box px={[6, 8, 50]} mt={60} mb={80}>
        {children}
      </Box>
    </Container>
  );
}
