import React from 'react';
import { Button } from '@bojagi/pablo/Button';
import { css } from 'styled-components';
import { Link } from 'gatsby';

const ArrowSvg = () => (
  <svg width="19" height="14" viewBox="0 0 19 14" fill="none">
    <path
      d="M16 6.828L16.172 6.99999L16 7.17197V6.828ZM15.172 6L11.222 2.04999L12.636 0.635986L19 6.99999L12.636 13.364L11.222 11.95L15.172 8H0V6H15.172Z"
      fill="currentColor"
    />
  </svg>
);

export function ArrowButton(props) {
  return (
    <Button
      as={Link}
      {...props}
      endIcon={<ArrowSvg />}
      minWidth={210}
      customStyles={{
        ...props.customStyles,
        primary: [
          props.customStyles?.primary,
          css`
            justify-content: space-between;
            box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.15);
          `,
        ],
        secondary: [
          props.customStyles?.secondary,
          css`
            justify-content: space-between;
          `,
        ],
      }}
    />
  );
}
