import { BaseTypographyProps, Paragraph } from '@bojagi/pablo/Typography';
import React from 'react';
import { css } from 'styled-components';

export function ParagraphLarge(props: BaseTypographyProps) {
  return (
    <Paragraph
      {...props}
      css={css`
        font-size: 1.125em;
      `}
    />
  );
}
